import './product-range-slider.scss';
import Swiper from 'swiper';
import { A11y, HashNavigation, EffectFade, Keyboard } from 'swiper/modules';

class ProductRangeSlider {
    constructor (element) {
        this.$slider = element;
        this.$sliderContainer = this.$slider.querySelector('[data-range-slider="slider"]');
        this.$trigger = this.$slider.querySelector('[data-anchor]');
    }

    initialize () {
        this.initSlider();
        this.setEvents();

        window.resizeHandler.customFunctions.push(() => {
            this.setEvents();
        });
    }

    setEvents () {
        const items = this.$slider.querySelectorAll('[data-anchor-item]');
        if (items) {
            items.forEach(item => {
                item.addEventListener('click', () => {
                    window.location.hash = item.getAttribute('data-anchor-item');
                });
            });
        }

        import('../../components/product-anchor/product-anchor').then(({ ProductAnchor }) => {
            const $productAnchor = new ProductAnchor(this.$trigger);
            $productAnchor.initialize();
        });
    }

    initSlider () {
        this.$sliderContainer = new Swiper(this.$sliderContainer, {
            modules: [A11y, HashNavigation, EffectFade, Keyboard],
            a11y: true,
            lazyPreloadPrevNext: true,
            keyboard: true,
            effect: 'fade',
            slidesPerView: 'auto',
            speed: 250,
            allowTouchMove: true,
            hashNavigation: {
                watchState: true
            },
            breakpoints: {
                1024: {
                    spaceBetween: 45
                },
                1280: {
                    allowTouchMove: false
                }
            }
        });
    }
}

export { ProductRangeSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-range-slider="root"]');
        if ($$roots.length > 0) {
            $$roots.forEach($root => {
                const $rootAPI = new ProductRangeSlider($root);
                $rootAPI.initialize();
            });
        }
    }
});
